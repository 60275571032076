import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { db } from '../../firebase/FireBaseConfig'
import { collection, addDoc } from 'firebase/firestore'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { useFormik, Formik, Form, Field } from "formik";
import axios from 'axios';

import { ExhibitorRegistrationvalid } from '../../Validation/exhibitorregistrationvalid';
const initialValues = {
    Name: '',
    Company: '',
    GST: '',
    MobileNo: '',
    City: '',
    EmailId: '',
    Country: '',
    Website: '',
    PinCode: '',
    Selectanoption: '',
    extra: [],
}
const options = [
    { value: 'accommodation', label: 'Accommodation' },
    { value: 'transportation', label: 'Transfer Transportation' },
    { value: 'DigitalDisplay', label: 'Digital Display' },
    { value: 'BrochureandStand', label: 'Brochure and Stand' },
  
];
function ExhibitorRegistration() {

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            console.log(11)
            // Make API call using Axios or your preferred HTTP client library
            const response = await axios.post('https://win.k2key.in/Newproject/index.php/Exhibitor/exhibitorRegistration', values);
            console.log(response.data); // Handle response data as needed

            setTimeout(() => {
                if
                    (response.data.res_code == 1) {
                    toast.success('Form submitted successfully! Please check your E-mail');
                    // Your form submission logic here
                    // toast.success('Form submitted successfully!');
                }

                if (response.data.res_code == 2) {
                    // toast.success('Form submitted successfully!');
                    toast.warning('User already exist!');
                    console.log("nhh")
                    // setSubmitting(false);
                }
                setSubmitting(false);
            }, 400);
        } catch (error) {
            console.error('Error:', error);
        }
        setSubmitting(false);
    };



    return (
        <Layout>
            <div className="">
                <div className='heartBanner relative'>
                    <div className='font-semibold text-4xl absolute z-20 text-white textVert'>
                        Exhibitor Registration
                    </div>
                    <img src="image/reg.jpg" />
                </div>

                <div className="container mx-auto px-4 my-10">

                    <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-2">
                        <div className='flex flex-col flex-auto overflow-hidden bgImgHalf'>

                        </div>
                        <div className='flex flex-col flex-auto overflow-hidden'>
                            <div className="p-8 bg-gray-50 rounded-xl border shadow-md">
                                <Formik initialValues={initialValues}
                                    validationSchema={ExhibitorRegistrationvalid}
                                    onSubmit={handleSubmit}
                                >
                                    {({ touched, errors }) => (
                                        <Form className="rounded-xl">
                                            <div className="grid gap-4 mb-6 md:grid-cols-2">
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Name *
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field type="text" name="Name" size="lg" placeholder=" Name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4"

                                                        ></Field>

                                                    </div>

                                                    {errors.Name && touched.Name ? (<small className="error">{errors.Name}</small>) : null}
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Exhibiting Company's Name *
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field type="text" name="Company" size="lg" placeholder="Enter Exhibiting Company's Name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4"

                                                        ></Field>

                                                    </div>

                                                    {errors.Name && touched.Company ? (<small className="error">{errors.Company}</small>) : null}
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        GST NO. *
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field type="text" name="GST" size="lg" placeholder="Enter GST NO. " className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4"

                                                        ></Field>

                                                    </div>

                                                    {errors.GST && touched.GST ? (<small className="error">{errors.GST}</small>) : null}
                                                </div>


                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Phone *
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field type="text" name="MobileNo" size="lg" placeholder=" Enter Phone No"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        ></Field>

                                                    </div>
                                                    {errors.MobileNo && touched.MobileNo ? (<small className="error">{errors.MobileNo}</small>) : null}
                                                </div>


                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        E-mail*
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field type="email" name="EmailId" size="lg" placeholder="  E-mail"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        ></Field>

                                                    </div>
                                                    {errors.EmailId && touched.EmailId ? (<small className="error">{errors.EmailId}</small>) : null}
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        City*
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field type="text" name="City" size="lg" placeholder=" Enter City " className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4"

                                                        ></Field>

                                                    </div>

                                                    {errors.City && touched.City ? (<small className="error">{errors.City}</small>) : null}
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Pin/Zip Code *
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field type="text" name="PinCode" size="lg" placeholder=" Enter Pincode" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4"

                                                        ></Field>

                                                    </div>

                                                    {errors.PinCode && touched.PinCode ? (<small className="error">{errors.PinCode}</small>) : null}
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        STALL AREA REQUIRED
                                                        Choose BUILDUP STALL SPACE or RAW SPACE

                                                    </label>
                                                    <div className="mt-2">
                                                        <Field as="select" name="Selectanoption" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4">
                                                            <option value="">Select an category</option>
                                                            <option value="9 Octanormstall"> 9 Sq.mtr Octanorm Stall </option>
                                                            <option value="12 Octanormstall">12 Sq.mtr Octanorm Stall</option>
                                                            <option value="above Octanormstall">Above Space 12 Sq.mtr for  Octanorm Stall</option> 
                                                            <option value="BAREOPENSPACE">Bare  Space</option>
                                                            <option value="BAREOPENSPACEabove"> Above Space 20 Sq.mtr for Bare Space</option>
                                                        </Field>
                                                        {errors.Selectanoption && touched.Selectanoption ? (<small className="error">{errors.Selectanoption}</small>) : null}
                                                    </div>

                                                </div>



                                                <div className="sm:col-span-3">
                                                    <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Additional

                                                    </label>
                                                    <div className="mt-2">
                                                        {options.map(option => (
                                                            <div key={option.value}>
                                                                <label>
                                                                    <Field type="checkbox" name="extra" value={option.value} />
                                                                    {option.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                        {/* {errors.Selectanoption && touched.Selectanoption ? (<small className="error">{errors.Selectanoption}</small>) : null} */}
                                                    </div>

                                                </div>


                                            </div>
                                            <div className="flex justify-center">
                                                <button type="submit" className="text-white bg-sky rounded-full text-md px-6 py-2.5 text-center shadow">Register</button>

                                            </div>
                                        </Form>

                                    )}

                                </Formik>
                                <ToastContainer />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default ExhibitorRegistration