import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Map from '../components/Map'
import Navbar2 from '../components/Navbar2'
import ScrollBtn from '../ScrollBtn/ScrollBtn'

function Stallinformation() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>

            <div className="main container mx-auto px-4 md:mt-28 lg:mt-28 mt-40">
                {/* <h1 className=' text-3xl font-bold text-center fonts2 mb-2'>VYAPR <span className=' text-[#ff0000]'>EXPO 2023
                    STAND INFORMATION GUIDE </span>

                </h1>
                <h3 className=' text-xl font-bold text-center mb-3 fonts2'>1ST NATIONAL
                    FOOD & <span className='text-[#ff0000]'>AGRICULTURE VYAPAR EXPO 2023
                        <br />
                        16-17-18-19-20 OCTOBER, 2023 <br /> AGRICULTURE GROUND (BHU CAMPUS), <br /> VARANASI Uttarpardesh </span>
                </h3> */}
            </div>

            <section className="text-black body-font bg-gray-50 px-12 py-6">
                <div className="md:w-4/5 lg:w-4/5 mx-auto flex py-8 md:flex-row flex-col items-center">
                    <div className="w-96 mb-10 md:mb-0">
                        <img className="object-cover object-center rounded" alt="hero" src="image/Pasted Graphic.png" />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        {/* <h1 className="title-font sm:text-4xl text-3xl mb-4 font-bold fonts2 text-gray-900">STALL SPECIFICATION

                        </h1>
                        <p className=" fonts text-lg "> <u>Rs.6500/-Per Sqm + GST 18% </u> <br />
                        OTHER UTILITIES <br />
                            <b> <u>BOOTH ACCESSORIES:</u></b> One Table, <br /> Two Chairs, 3 Spot Light, Dust Bin, <br /> Fascia, Carpet, Electric Point , Brochure stand , <br /> Entry on catalogue  listing & entry pass <br />
                           
                        </p> */}
                        <p className="text-2xl font-medium">Octanorm stall for emerging and enterprising companies that have transformed innovations into products.</p>
                        <p className="text-2xl font-medium">Get your space in the form of Octanorm stall at a subsidised rate.</p>
                    </div>
                </div>
            </section>
            <div className=" container mx-auto px-4">
                {/* <ul className=' list-disc mx-5 mb-5'>
              <li className=' text-lg fonts4'>Exhibitor should pay additional charges for any other utilities  
              </li>
              <li className=' text-lg fonts4'>Flex branding , Digital display , Air compressor & Electricity 
              </li>
            </ul>
            <h1 className=' text-xl font-bold my-3'>Space is available in 6 Sq.Mtr , 9 Sq.Mtr , 12 & 15 Sq.Mtr and above as per requirements 
</h1> */}
            </div>


            <div className="my-10">
                <img className="object-cover object-center rounded w-3/4 mx-auto" src="image/Picture2.png" />
            </div>

             {/*  table1*/}
            <div class="flex flex-col">
  <div class="overflow-x-auto">
    <div class="md:w-4/5 lg:w-4/5 p-6 md:py-16 lg:py-16 mx-auto">
      <div class="overflow-auto">
        <table class="min-w-full border text-center text-sm font-light border-orange-500">
          <thead class="border-b font-medium border-orange-500 text-white bg-orange-500">
            <tr>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">STALL TYPE</th>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">AREA IN SQ.M</th>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">DEPTH</th>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">RATE IN Rs</th>
            </tr>
          </thead>
          <tbody className='bgTble'>
            <tr class="border-b border-orange-500">
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">Prefabricated stall</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">9</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">3</td>
                        <td rowspan="1" class="border-r px-6 py-4 border-orange-500 font-semibold text-xl">
                        20,000 (Including 18% GST)
                       
                         {/* <ul className='list'>
                                <li>20,000 (Including 18% GST)</li>
                                <li>24,000 (Including 18% GST)</li>
                          
                            </ul> */}
                        </td>
                      
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">Prefabricated stall</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">12</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">3</td>
                        <td rowspan="1" class="border-r px-6 py-4 border-orange-500 font-semibold text-xl">
                        24,000 (Including 18% GST)
                       
                         {/* <ul className='list'>
                                <li>20,000 (Including 18% GST)</li>
                                <li>24,000 (Including 18% GST)</li>
                          
                            </ul> */}
                        </td>
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td colspan="4" class="text-left border-r px-6 py-4 border-orange-500 font-semibold text-xl">
                        Facilities :-3 Chairs , 3 Spot lamps, 1 Reception counter , Carpet , One 5 amp power point , Exhibitor name on Fascia , Stall with all side branding
                       
                        </td>
                        {/* <td class="border-r px-6 py-4 border-orange-500 font-bold text-2xl vBottom">55,000 Rs</td> */}
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td colspan="4" class="border-r px-6 py-4 border-orange-500 font-bold text-xl">ABOVE 12 SQ MTR EXTRA CHARGES 2000 Rs. PER SQ MTR</td>
                    </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
           {/*  table2 */}
<div class="flex flex-col">
  <div class="overflow-x-auto">
    <div class="md:w-4/5 lg:w-4/5 p-6 md:py-16 lg:py-16 mx-auto">
      <div class="overflow-auto">
        <table class="min-w-full border text-center text-sm font-light border-orange-500">
          <thead class="border-b font-medium border-orange-500 text-white bg-orange-500">
            <tr>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">STALL TYPE</th>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">AREA IN SQ.M</th>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">DEPTH</th>
              <th scope="col" class="border-r p-3 border-orange-500 text-2xl">RATE IN Rs</th>
            </tr>
          </thead>
          <tbody className='bgTble'>
            <tr class="border-b border-orange-500">
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">Prefabricated stall </td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">9</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">3</td>
                        <td rowspan="2" class="border-r px-6 py-4 border-orange-500 font-semibold text-xl">
                            <ul className='list'>
                                <li>Octanorm stall with complete branding</li>
                                <li>	Hotel Accommodation </li>
                                <li>Transfers</li>
                                {/* <li>3 days meals </li> */}
                                <li>	Meals (Breakfast ,Lunch &Dinner) </li>
                            </ul>
                        </td>
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">Prefabricated stall</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">12</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">3</td>
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td colspan="3" class="text-left border-r px-6 py-4 border-orange-500 font-semibold text-xl">
                        Facilities :-3 Chairs , 3 Spot lamps, 1 Reception counter , Carpet , One 5 amp power point , Exhibitor name on Fascia , Stall with all side branding 
                            {/* <p></p> */}
                        </td>
                        <td class="border-r px-6 py-4 border-orange-500 font-bold text-2xl vBottom">Total charges - 65,000 Rs. (Including 18% GST)</td>
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td colspan="4" class="border-r px-6 py-4 border-orange-500 font-bold text-xl">ABOVE 12 SQ MTR EXTRA CHARGES 2000 Rs. PER SQ MTR.</td>
                    </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<section className="text-black body-font bg-gray-50 p-6 py-6">
                <div className="md:w-4/5 lg:w-4/5 mx-auto flex py-8 md:flex-row flex-col items-center">

                    <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-bold fonts2 text-gray-900">CUSTOMIZED STALL</h1>

                        <p className="text-2xl font-medium lg:pr-16 md:pr-16">Bare space, Exhibitor can customized there space as per there requirement.</p>

                    </div>
                    <div className="w-96 mb-10 md:mb-0">
                        <img className="object-cover object-center rounded" src="https://cdn.dribbble.com/users/745986/screenshots/4295597/media/edcc2efae20cf2d6c42f82bc1f5e3d3f.jpg" />
                    </div>
                </div>
            </section>

<div class="flex flex-col">
  <div class="overflow-x-auto">
    <div class="md:w-4/5 lg:w-4/5 p-6 md:py-16 lg:py-16 mx-auto">
      <div class="overflow-auto">
            <table class="min-w-full border text-center text-sm font-light border-orange-500">
                <thead class="border-b font-medium border-orange-500 text-white bg-orange-500">
                    <tr>
                        <th colspan="2" scope="col" class="border-r p-3 border-orange-500 text-2xl"> Bare space charges</th>
                    </tr>
                    </thead>
                    <tbody className='bgTble'>
                     <tr class="border-b border-orange-500">
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">Bare Space Upto 20 SQ.MTR</td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">30,000 Rs. (Including 18% GST)  </td>
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl"> </td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">
                            <ul className='list'>
                                <li>	Hotel Accommodation</li>
                                <li>	Transfers </li>
                                <li>	Meals (Breakfast ,Lunch & Dinner) </li>
                                {/* <li>(Break  Fast ,Lunch & Dinner )</li> */}
                            </ul>
                        </td>
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">Total charges </td>
                        <td class="border-r px-6 py-2 borderborder-r px-6 py-2 border-orange-500 font-semibold text-xl">75,000 Rs. (Including 18% GST) </td>
                    </tr>

                    <tr class="border-b border-orange-500">
                        <td colspan="2" class="border-r px-6 py-4 border-orange-500 font-bold text-xl">ABOVE 20 SQ.MTR  - 1500 PER SQ.MTR</td>
                    </tr>
                    <tr class="border-b border-orange-500">
                        <td colspan="2" class="border-r px-6 py-4 border-orange-500 font-bold text-xl">Note: The Bare space holder has to pay electricity charges as per their consumption.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
         </div>
      </div>
    </div>


  <section className=" bg-gray-50 p-6 py-6">
  <div class="md:w-4/5 lg:w-4/5 p-6 md:pb-16 lg:pb-16 mx-auto">
    <h2 class="mb-20 text-center text-4xl font-bold">
      Additional/Optional Services
    </h2>

    <div class="">
      {/* flex flex-wrap items-center */}
      {/* <div class="my-3 w-full shrink-0 grow-0 basis-auto md:px-6 lg:mb-0 lg:w-5/12">
        <div class="relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="https://img.freepik.com/free-photo/partnership-farmers_1098-14821.jpg?t=st=1709373102~exp=1709376702~hmac=dc91d67085fd07096441633d9f4892b0a1a7d29364eb0eb664865ac765582eb7&w=900" class="w-full" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,0%,0.4)] bg-fixed">
              <div class="flex h-full items-center justify-center">
                <div class="px-6 py-12 text-center text-white md:px-12">
                  <h3 class="mb-6 text-2xl font-bold uppercase">
                    The future is
                    <u class="text-[hsl(210,12%,80%)] ml-2">now</u>
                  </h3>
                  <p class="text-[hsl(210,12%,80%)]">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Harum quia laboriosam error consequuntur fugit,
                    doloribus rerum, iure nesciunt amet quidem veniam
                    cupiditate hic fugiat dolore aperiam quisquam libero
                    earum quibusdam?
                  </p>
                </div>
              </div>
            </div>
            <div class="relative overflow-hidden bg-cover bg-no-repeat">
              <div
                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
              </div>
            </div>
          </a>
        </div>
      </div> */}

      <div class="w-full grid grid-cols-2 gap-2 md:pl-20">
        <div class="my-3 flex items-center md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Additional Furniture/Electrical</p>
            {/* <p class="text-neutral-500 dark:text-neutral-300">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Nihil quisquam quibusdam modi sapiente magni molestias
              pariatur facilis reprehenderit facere aliquam ex.
            </p> */}
          </div>
        </div>

        <div class="my-3 flex items-center md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Power Load/Supply</p>
            {/* <p class="text-neutral-500 dark:text-neutral-300">
              Eum nostrum fugit numquam, voluptates veniam neque quibusdam
              ullam aspernatur odio soluta, quisquam dolore animi mollitia a
              omnis praesentium, expedita nobis!
            </p> */}
          </div>
        </div>

        <div class="my-3 flex items-center md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Audio Visual Equipment</p>
            {/* <p class="text-neutral-500 dark:text-neutral-300">
              Enim cupiditate, minus nulla dolor cumque iure eveniet facere
              ullam beatae hic voluptatibus dolores exercitationem? Facilis
              debitis aspernatur amet nisi iure eveniet facere?
            </p> */}
          </div>
        </div>

        <div class="my-3 flex items-center md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Telephone/Fax/Internet Connections</p>
            {/* <p class="text-neutral-500 dark:text-neutral-300">
              Illum doloremque ea, blanditiis sed dolor laborum praesentium
              maxime sint, consectetur atque ipsum ab adipisci ullam
              aspernatur odio soluta, quisquam dolore
            </p> */}
          </div>
        </div>

        <div class="my-3 items-center flex md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Audiovisual Systems</p>
          </div>
        </div>

        <div class="my-3 items-center flex md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Design & Consrtruction Services</p>
          </div>
        </div>

        <div class="my-3 items-center flex md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Hostess/Temporary Promotional Staff</p>
          </div>
        </div>

        <div class="my-3 items-center flex md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Stand Cleaning Service</p>
          </div>
        </div>

        <div class="my-3 items-center flex md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg">Security Services</p>
          </div>
        </div>

        <div class="my-3 items-center flex md:pl-20">
          <div class="shrink-0">
            <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
          </div>
          <div class="ml-4 grow">
            <p class="mb-1 font-bold text-lg"> Cargo Movement & Clearances</p>
          </div>
        </div>

      </div>
    </div>
    </div>
  </section>




    <ScrollBtn />

    </Layout>

    )
}

export default Stallinformation