import React from 'react'
import Layout from '../../components/Layout'
import { useNavigate } from 'react-router-dom';
// import { Link } from "react-router-dom";
import { VisitorRegistrationvalid } from '../../Validation/VisitorRegistrationvalid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import { useFormik, Formik, Form, Field } from "formik";
import axios from 'axios';

const initialValues = {
  Name: '',
  CompanyName: '',
  email: '',
  Designation: '',
  Phone: '',
  Website: '',
  Country: '',
  State: '',
  Selectanoption: '',
  Social: '',
  City: '',


}


function VisitorRegistration() 
{
 var notify= () => toast("Wow so easy!");
  // const navigate = useNavigate();

  const handleSubmit = async (values,{ setSubmitting }) => {
    
    
    try {
      // Make API call using Axios or your preferred HTTP client library
      const response = await axios.post('https://win.k2key.in/Newproject/index.php/Login/register', values);
      console.log(response.data); 
     
      
      setTimeout(() => {
      if
       (response.data.res_code==1){
        toast.success('Form submitted successfully! Please check your E-mail');
            // Your form submission logic here
            // toast.success('Form submitted successfully!');
          }
          
      if (response.data.res_code==2)
      {
        // toast.success('Form submitted successfully!');
        toast.warning('User already exist!');
        console.log("nhh")
        // setSubmitting(false);
      }
      setSubmitting(false);
    }, 400);
      // console.log(response.data);// Handle response data as needed
    } catch (error) {
      console.error('Error:', error);
    }
  
  };


  return (
    <Layout>

      <div className='heartBanner relative'>
        <div className='font-semibold text-4xl absolute z-20 text-white textVert'>
          Visitors Registration
        </div>
        <img src="https://img.freepik.com/free-photo/farmer-holds-rice-hand_1150-6063.jpg?size=626&ext=jpg&uid=R65076695&ga=GA1.1.1813029076.1708161511&semt=sph" />
      </div>

      <div className="container mx-auto px-4 my-10">

        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-2">
          <div className='flex flex-col flex-auto overflow-hidden bgImgHalf2'>
          </div>

          <div className='flex flex-col flex-auto overflow-hidden'>

            <div className="outbgform p-8 bg-gray-50 rounded-xl border shadow-md">
              <div className="left mb-5">
                <div className="">
                  <div className=" flex justify-center">
                    <img className='w-28 ' src="image/logot.png" alt="" />
                  </div>
                  {/* <h1 className=' mb-5 text-center text-xl fonts1 font-bold'>
                    1ST NATIONAL FOOD & AGRICULTURE <br />
                    VYAPAR EXPO 2023 <br />
                    16-17-18-19-20 OCTOBER 2023 </h1> */}
                </div>
                {/* <h1 className=' text-center text-2xl underline fonts1 font-bold'>
                            Visitors Registration</h1> */}
              </div>
              <div className="right">
                <div className="inner">
                  <div className="">

                    {/* formik form */}
                    <Formik initialValues={initialValues}
                      validationSchema={VisitorRegistrationvalid}
                      onSubmit={handleSubmit}
                    >
                      {({ touched, errors }) => (
                        //  className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2 "
                        <Form >

                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Name *
                              </label>
                              <div className="mt-2">
                                <Field type="text" name="Name" size="lg" placeholder=" Name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4"
                                // className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                ></Field>

                              </div>
                              {/* {errors.name && touched.name } */}
                              {errors.Name && touched.Name ? (<small className="error">{errors.Name}</small>) : null}
                            </div>




                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Company Name *
                              </label>
                              <div className="mt-2">
                                <Field type="text" name="CompanyName" size="lg" placeholder=" Company  Name"
                                  className="block pl-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                ></Field>

                              </div>
                              {errors.CompanyName && touched.CompanyName ? (<small className="error">{errors.CompanyName}</small>) : null}
                            </div>


                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                E-mail *
                              </label>
                              <div className="mt-2">
                                <Field type="email" name="email" size="lg" placeholder="  E-mail"
                                  className="block pl-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                ></Field>

                              </div>
                              {errors.email && touched.email ? (<small className="error">{errors.email}</small>) : null}
                            </div>

                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Designation
                              </label>
                              <div className="mt-2">
                                <Field type="text" name="Designation" size="lg" placeholder=" Enter Designation"
                                  className="block pl-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                ></Field>

                              </div>

                            </div>


                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Phone *
                              </label>
                              <div className="mt-2">
                                <Field type="text" name="Phone" size="lg" placeholder=" Enter Phone"
                                  className="block pl-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                ></Field>

                              </div>
                              {errors.Phone && touched.Phone ? (<small className="error">{errors.Phone}</small>) : null}
                            </div>




                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Select your category
                              </label>
                              <div className="mt-2">
                                <Field as="select" name="Selectanoption"  className="block px-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <option value="">Select an category</option>
                                  <option value="Student">Student</option>
                                  <option value="Governmentsector">Government sector</option>
                                  <option value="Retailer">Retailer</option>
                                  <option value="distributor">Distributor</option>
                                  <option value="privatesector">Private sector</option>
                                </Field>

                              </div>

                            </div>


                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Select an option

                              </label>
                              <div className="mt-2">
                                <Field as="select" name="Social"  className="block px-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <option value="">Select an option</option>
                                  <option value="email">E-mail</option>
                                  <option value="Facebook">Facebook Advertisement</option>
                                  <option value="Whatsapp">Whatsapp</option>
                                  <option value="NewsPaper">News Paper</option>
                                  <option value="Telecalling">Telecalling</option>
                                  <option value="OutdoorAdvertising">Outdoor Advertising</option>
                                  <option value="Website">Website</option>
                                </Field>

                              </div>

                            </div>



                            <div className="sm:col-span-3">
                              <label htmlFor="name-name" className="block text-sm font-medium leading-6 text-gray-900">
                               Address

                              </label>
                              <div className="mt-2">
                                <Field as="textarea" name="City" className="block pl-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                  cols={30}
                                  placeholder={`Please Enter your Address `}
                                  rows={4}
                                > </Field>

                              </div>

                            </div>

                           


                          </div>
                         

                          <div className="flex justify-center">
                            <button type="submit"   className="text-white bg-sky rounded-full text-md px-6 py-2.5 text-center shadow mt-6">Register Now</button>
                          </div>

                          {/* </Link> */}



                        </Form>
                      )}

                    </Formik>
                    <ToastContainer />
                    {/* formik form */}

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>



      </div>
    
    </Layout>

  )
}

export default VisitorRegistration