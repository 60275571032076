import React from 'react'
import { Link } from 'react-router-dom'
function support() {
  return (
    <section className="text-gray-600 body-font">
            <div className="container md:px-0 px-5 mt-8 py-5 mx-auto">

                <div className="flex flex-wrap">
                    <div className="p-2 sm:w-1/4 w-full">
                        {/* <Link to={'/exhibitorregistration'}> */}
                            <div className="bg-[#EAF0EC] text-center rounded-xl organizerCard">
                               <img src='image/logot.png' className='w-32 pt-4 mx-auto' />
                               <div className='orgTxt'>Organized By</div>
                            </div>
                        {/* </Link> */}
                    </div>

                    <div className="p-2 sm:w-1/4 w-full">
                        {/* <Link to={'/visitorregistration'}> */}
                        <div className="bg-[#EAF0EC] text-center rounded-xl organizerCard">
                               <img src='image/msme.png' className='w-32 pt-4 mx-auto' />
                               <div className='orgTxt'>Knowledge Partner </div>
                            </div>
                        {/* </Link> */}
                    </div>

                    <div className="p-2 sm:w-1/4 w-full">
                        {/* <Link to={'/registrationforsponsorship'}> */}
                        <div className="bg-[#EAF0EC] text-center rounded-xl organizerCard">
                               <img src='image/upg.png' className='w-32 pt-4 mx-auto' />
                               <div className='orgTxt'>Supported By</div>
                            </div>
                        {/* </Link> */}
                       
                    </div>
                    
                    <div className="p-2 sm:w-1/4 w-full">
                        <a href='pdf/01.OPTIONS0_sign (2).pdf'>
                        <div className="bg-[#EAF0EC] text-center rounded-xl organizerCard">
                               <img src='image/apida.png' className='w-32 pt-4 mx-auto' />
                               <div className='orgTxt'>Promoted By</div>
                            </div>
                        </a>
                    </div>

                </div>

            </div>
        </section>
  )
}

export default support