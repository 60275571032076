import React from 'react'
import '../styleSheet/Hero.css'
import { IoLogoWhatsapp } from 'react-icons/io'
import { BsFacebook } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
function Hero() {
    return (
        <section className="relative mt-[88px] h-screen flex flex-col md:items-start  justify-center    text-white py-0 px-3">
           
            <div className="video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
            <video className="min-w-full min-h-full absolute object-cover" src="image/My Video6.mp4" type="video/mp4" autoPlay muted loop />
                {/* <video className="min-w-full min-h-full absolute object-cover" src="https://res.cloudinary.com/dkjgzcnzu/video/upload/v1680684013/My_Video4_ssvcoz.mp4" type="video/mp4" autoPlay muted loop /> */}
                <img src="image/homeyogi.png" className='yoginath' alt="Yogi Adityanath" />
            </div>

            <div className="video-content space-y-2 md:ml-[70px]">
         
                {/* <motion.h1 className="font-bold text-xl md:text-2xl mb-5 fonts1 uppercase "
                    animate={{ x: [50, 150, 50], opacity: 1, scale: 1 }}
                    transition={{
                        duration: 5,
                        delay: 0.3,
                        ease: [0.5, 0.71, 1, 1.5],
                    }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    whileHover={{ scale: 1.2 }}
                >
                    UTTAR PRADESH
                    AGRICULTURE & FOOD VYAPAR <br />TRADE SHOW

                    JOIN THE LEADING MANUFACTURER & BRANDS

                </motion.h1> */}

                <div className='font-bold text-4xl'>
                    UTTAR PRADESH  VYAPAR <br />TRADE SHOW(UPVTS)
                    {/* JOIN THE LEADING <br /> MANUFACTURER & BRANDS */}
                </div>

                <div className='text-lg pt-4'>JOIN THE LEADING BRAND AND MANUFACTURER </div>
                <div >JANUARY, 2025  VARANASI</div>
             
                {/* <div className="flex space-x-2">
                    <div className="icon">
                        <IoLogoWhatsapp color='white' size={30} />
                    </div>
                    <div className="icon">
                        <BsFacebook color='white' size={30} />
                    </div>
                    <div className="icon">
                        <AiFillInstagram color='white' size={30} />
                    </div>
                </div> */}
                <div className='py-3 pt-16'>
                    <Link to={'/exhibitorregistration'}>
                        <button type="button" class="text-white bg-red-600 rounded-full text-sm px-5 py-2.5 text-center mr-2 shadow">Exhibitor Registration </button>
                    </Link>
                    <Link to={'/visitorregistration'}>
                        <button type="button" class="text-white bg-green-600 rounded-full text-sm px-5 py-2.5 text-center shadow">Visitor  Registration</button>
                    </Link>

                </div>

           

                <div>
                   
                </div>
            </div>
            
        </section>
    )
}

export default Hero