import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import Navbar2 from '../../components/Navbar2'

function AboutOrganizer() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>

        <div className='heartBanner1 relative '>
            {/* <div className='font-bold text-4xl absolute z-20 text-white text-center textVert'>
                 ABOUT UPVTS 2025 
              <span className='font-medium text-xl mt-4 text-white block'>
                UTTAR PRADESH VYAPAR TRADE SHOW 2025
               </span>
            </div> */}
            
            <img src="image/WhatsApp.jpg"/>
        </div>
  
        <div className="container mx-auto px-14 my-14">
  
        <div class="sm:grid grid-cols-4 gap-4">
          
           <div class="col-span-3">
           <div className='font-bold text-4xl z-20'>
                 ABOUT UPVTS 2025 
              <span className='font-medium text-2xl my-4 block'>
                UTTAR PRADESH VYAPAR TRADE SHOW 2025
               </span>
            </div>
           <div className="para ">
              <p className='text-justify text-xl'>
              Uttar Pradesh   Vyapar Trade Show (UPVTS) 2025 scheduled from September , 2025 at Varanasi, UPVTS is the flagship to upscale the industrial ecosystem in Uttar Pradesh. The 3 days trade show will bring together all the food and agriculture   manufacturer, stake holder corporate leader   , business delegation, academia and government leadership from across the India, to collectively   explore business opportunities and forge partnerships.
                <br/>
                <br/>
                UPVTS 2025 is an initiative aligned to give some profitable involvement & set aspiration to achieve to the vision of the Respected Chief Minister of Uttar Pradesh of making Uttar Pradesh a USD 1 Trillion economy.  
                <br/>
                UPVTS 2025 will witness knowledge sessions, cross –border investment commitments and the trade show will be the amalgamation of Uttar Pradesh business   , political & government leader     coming together    for the economic development of our Uttar Pradesh.  
                <br/>
              </p>
              <p className='text-justify mb-10 text-xl'>
                You are invited to be the part of Uttar Pradesh Vyapar Trade Show 2025 and witness the commitment of inclusive development of Uttar Pradesh   being realized 
            </p>
              </div>
           </div>
           <div> <img className='w-80 p-8' src="image/upg.png" alt="logo" /></div>
        </div>
        <div className="para my-5 ">
           
        </div>
         
        </div>
          <div className="container mx-auto px-4">
            <div>
              {/* <IconsForAbout /> */}
            </div>
          </div>
        </Layout>
    )
}

export default AboutOrganizer