import React from 'react'

function Team() {
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-col text-center w-full mb-10">
            <h1 className=' text-center fonts2 font-bold text-2xl md:text-3xl'>WHO SHOULD <span className="text-[#FF0000]"> ATTEND</span></h1>

          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

            <div className='p-20 rounded-md bg-exbi text-white '>
              <h1 className='text-4xl mb-4'>EXHIBITORS</h1>
              An ultimate show that will feature a whole gamut of recognized and unrecognized products, makers, and suppliers from the state of Uttar Pradesh has to offer. Here is a quick look:

            </div>
            <div className='p-10 text-black font-semibold'>
              <h2>Agriculture & Horticulture
                | Defence Corridor |<br></br>
                E - Commerce, Banking, Financial Services|<br></br>
                Education Sector (University/STEM/Book Show)|<br></br>
                Infra, Engineering & Manufacturing|
                Film Sector|<br></br>
                Food Processing & Dairy Products|
                Gl Tag Products|<br></br>
                Handloom, Handicrafts & Textiles, Retail<br></br>
                Health & Wellness (Ayush, Yoga, Unani, Naturopathy)|
                IT/ITES|<br></br>
                MSMEs|
                Namami Gange & Jal Shakti|
                ODOP|<br></br>
                Renewable Energy & EV
                Sports|
                Tourism & Hospitality|<br></br>
                Toy Association & Clusters of UP|
                Warehouse & Logistics|<br></br>
                Women Entrepreneurs
                And Many Others...</h2>
              {/* <img className='rounded-xl' src="image/EXHIBITOR-PROFILE-FOOD.png" /> */}
            </div>


          </div>
          {/* visitor  */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

          
            <div className='p-10 text-black font-semibold'>
              <h2>Buying Agents
                |Corporates |   Distributors | Domestics buyer |<br></br>
              E-commerce|Exporters | Institutional Buyers|
                Investors|<br></br>
                Marketing Professionals<br></br>
                Overseas Buyers, Importers & Wholesalers|
                Retail Stores|<br></br>
                Think Tanks|
                Traders</h2>
              {/* <img className='rounded-xl' src="image/EXHIBITOR-PROFILE-FOOD.png" /> */}
            </div>

            <div className='p-20 rounded-md bg-visi text-white '>
              <h1 className='text-4xl mb-4'>VISITORS</h1>
              An ideal platform for Indian and overseas audiences, business visitors, and other stakeholders who are associated directly or indirectly with the development model of the state of Uttar Pradesh. Here's a brief glance:

            </div>


          </div>
          {/* visitor  */}


          {/* <div className="flex flex-wrap -m-2">
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/WhatsApp Image 2023-04-13 at 1.29.57 PM.jpeg" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Mr.  KUNAL MISHRA</h2>
                  <p className="text-gray-500">Founder Vyapar India</p>
                </div>
              </div>
            </div>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/06fab93c-d482-4f47-a036-106062430ca8.jpg" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Mr . AKASH VERMA</h2>
                  <p className="text-gray-500">Director</p>
                </div>
              </div>
            </div>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/ajay.JPG" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Mr. Ajay Kr. Yadav</h2>
                  <p className="text-gray-500">Discipline Secretary</p>
                </div>
              </div>
            </div>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/mohit.JPG" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Mr. MOHIT GUPTA</h2>
                  <p className="text-gray-500">Digital Pramotion Incharge</p>
                </div>
              </div>
            </div> 
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/WhatsApp Image 2023-04-13 at 1.32.21 PM-modified.png" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Mr. Rupesh Pathak </h2>
                  <p className="text-gray-500">Digital Pramotion Management</p>
                </div>
              </div>
            </div>
             <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/WhatsApp Image 2023-04-13 at 1.33.49 PM.jpeg" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Sahil yadav</h2>
                  <p className="text-gray-500">Marketing Incharge</p>
                </div>
              </div>
            </div>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/WhatsApp Image 2023-04-13 at 1.32.02 PM-modified.png" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">ADITI SINGH</h2>
                  <p className="text-gray-500">Accounts & Management</p>
                </div>
              </div>
            </div>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="team/WhatsApp Image 2023-04-16 at 10.46.13 PM-modified.png" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Mr. Utkarsh Keshari</h2>
                  <p className="text-gray-500">Exhibitors Management Incharge</p>
                </div>
              </div>
            </div>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/88x88" />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">Mr. Manjeet Bhardwaj</h2>
                  <p className="text-gray-500">Marketing Co-Incharge</p>
                </div>
              </div>
            </div>
           
           
           
          </div> */}
        </div>
      </section>

      <section>
        <div className="m-10 mb-20 h-full flex flex-col items-center justify-center p-4 rounded-lg">
          <img alt="team" className="w-36 h-36 border-2 border-white shadow-md bg-gray-100 object-cover object-center flex-shrink-0 rounded-full" src="team/kunal.jpg" />
          <div className="text-center max-w-screen-lg">
            <h1 className="text-gray-900 text-2xl font-bold mt-2">CEO Vyapar India</h1>
            <h2 className="text-gray-900 my-2 title-font font-medium">Mr. KUNAL MISHRA</h2>
            {/* <p className="text-gray-700">Founder Vyapar India</p> */}
            <blockquote>
              UPVTS 2025 aims to gather India leading manufacturer , brand players and stakeholders from the India agri-food industry to share best practices, showcase the latest technologies, to tap business opportunities, and to collaborate towards greater security and sustainability in India
            </blockquote>
          </div>
        </div>

      </section>



    </div>
  )
}

export default Team