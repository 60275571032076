import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import IconsForAbout from './IconsForAbout'

export default function AboutVyaparXpo() {
  return (
    <div>
      <Layout>

      <div className='heartBanner relative'>
          <div className='font-bold text-4xl absolute z-20 text-white text-center textVert'>
               ABOUT US
            <span className='font-medium text-xl mt-4 text-white block'>
                VIA –Vyapar India Association
             </span>
          </div>
          
          <img src="image/farmerx.jpg" />
      </div>

      <div className="container mx-auto px-14 my-14">

      <div class="sm:grid grid-cols-4 gap-4">
         <div> <img className='w-80' src="image/kunal.jpg" alt="logo" /></div>
         <div class="col-span-3">
         <div className="para ">
            <p className='text-justify text-xl'>
              Established in 2022, VIA is the business organization in India. Its industrialization and its emergence as one of   the most rapidly growing global economies.
              <br/>
              <br/>
              A non-government, not-for-profit organization, <b>VIA (VYAPAR INDIA ASSOCIATION)</b> is the voice of India's business and industry. From influencing policy to encouraging debate, engaging with policy makers and civil society, VIA articulates the views and concerns of industry. It serves its members from the Indian private and public corporate sectors and multinational companies, drawing its strength from diverse regional chambers of commerce and industry across states. <br />
              <br/>
              VIA provides a platform for networking and consensus building within and across sectors and is the first port of call for Indian industry, policy makers and the international business community. VIA has unique distinction of representing a strong MSME base, which embodies the spirit of entrepreneurship and talent. Encouraged by a favorable macro-economic environment, the segment is now poised to grow and contribute to global value chains.
              <br/>
            </p>
            </div>
         </div>
      </div>
      <div className="para my-5 ">
              <p className='text-justify mb-10 text-xl'>
                Today , India  is being viewed by the world with confidence and optimism . The “New India” concept is driving change and as collaborative partner, <b> VIA (VYAPAR INDIA ASSOCIATION)</b> will help usher in a resurgent  superpower, a New India.
              <br/>
              <br/>
              <b>VYAPAR INDIA ASSOCIATION</b> is committed to building trust and relationship among members, enhancing social amalgamation and partnering with government entities. Going forward the core thrust areas will be promotion of small and medium enterprises, employment generation , enhancement  of skilled labor , mainstreaming women workforce, enabling sustainable development and encouraging entrepreneurship.
              <br/>
              <br/>
              <b>VYAPAR INDIA ASSOCIATION</b> believes that Indian would be in the forefront of the next century and remains committed and poised to driving future prospects.
              </p>
                  {/* <div className="flex justify-center mb-2">
                      <Link to={'/exhibitorregistration'} ><button type="button" className="text-black bg-yellow-400 hover:bg-purple-800font-medium rounded-xl text-sm px-5 py-2.5 text-center mr-2 fonts2 ">BOOK YOUR STAND SPACE </button></Link>
                      <Link to={'/visitorregistration'} ><button type="button" className="text-black bg-yellow-400 hover:bg-purple-800font-medium rounded-xl text-sm px-5 py-2.5 text-center mr-2 fonts2 ">VISIT FOR FREE </button></Link>
                  </div>         */}

          <div className="download text-center my-8 shadow textgreen-500 font-bold bg-green-100 rounded-full">
              <a href='pdf/Brochure.pdf' className='flex items-center '>
              <img src="image/arrow.png"  className='w-16 mr-2' />DOWNLOAD BROCHURE
              </a>
            </div>

      </div>
       
      </div>
        <div className="container mx-auto px-4">
          <div>
            {/* <IconsForAbout /> */}
          </div>
        </div>
      </Layout>
    </div>
  )
}
