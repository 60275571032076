import React, { useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Exhibitor() {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    className: "myCustomCarousel"
  };

  return (
    <div className='my-12 py-5'>
      <div className='container mx-auto '>
        <section className="">
          <h1 className='uppercase text-center font-bold text-2xl md:text-3xl'>
            Welcome tO Varanasi
          </h1>
          <h1 className='uppercase mt-2 text-center font-bold text-2xl md:text-3xl'>
            {/* 1ST International Agriculture & Food Vyapar Trade Show */}
            1ST UTTAR PRADESH VYAPAR TRADE SHOW 2025 
          </h1>
          {/* <p className='uppercase text-center mt-2'>Uttar Pradesh International Agriculture & Food Vyapar Trade Show</p> */}

          <div className="pt-12 pb-20 mx-auto max-w-screen-xl px-4">
            <div className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-3 imghover">
              <div><img src="gallery/Picsart_23-03-08_12-54-58-487.jpg" className='rounded-2xl' /></div>
              <div><img src="gallery/10.jpg" className='rounded-2xl' /></div>
              <div><img src="gallery/4.jpg" className='rounded-2xl' /></div>
            </div>
          </div>

        </section>

        <h1 className='text-center font-bold text-2xl md:text-3xl mb-20'>OUR <span className=" text-[#FF0000]">EXHIBITOR</span></h1>
        {/* <section className="">
          <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
            <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 ">
              <a href="#" className="">
                <img src="exhibitor/001.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/002.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/003.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/010.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/011.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/012 (1).png" alt="" />
              </a>
            </div>
          </div>
          <div className="mx-auto max-w-screen-xl px-4">
            <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 ">
              <a href="#" className="">
                <img src="exhibitor/013.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/014.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/016.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/020.png" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/073.png" alt="" />
              </a>
              <a href="#" className="">
                <img className='w-28' src="exhibitor/leaf1.png" alt="" />
              </a>
            </div>
          </div>
          <div className="mx-auto max-w-screen-xl px-4">
            <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 ">
              <a href="#" className="">
                <img src="exhibitor/th (2).jpg" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/th (3).jpg" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/th (4).jpg" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/th (5).jpg" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/th (7).jpg" alt="" />
              </a>
              <a href="#" className="">
                <img src="exhibitor/untitled-1-page-0001-90x90.webp" alt="" />
              </a>
            </div>
          </div>

        </section> */}

      </div>

      {/* slider */}
      <Slider {...settings}  className='imagescroll'>
        
        <div>
          <a href="#" className="">
            <img src="exhibitor/001.png" alt="" />
          </a>
        </div>
        <div>
          <a href="#" className="">
            <img src="exhibitor/002.png" alt="" />
          </a>
        </div>
        <div>
          <a href="#" className="">
            <img src="exhibitor/003.png" alt="" />
          </a>
        </div>
        <div>
          <a href="#" className="">
            <img src="exhibitor/010.png" alt="" />
          </a>
        </div>
        <div>
        <a href="#" className="">
                <img src="exhibitor/011.png" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="">
                <img src="exhibitor/012 (1).png" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="">
                <img src="exhibitor/013.png" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="">
                <img src="exhibitor/014.png" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="">
                <img src="exhibitor/016.png" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="">
                <img src="exhibitor/020.png" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="">
                <img src="exhibitor/073.png" alt="" />
              </a>
        </div>
       
        <div>
        <a href="#" className="imagebackground">
                <img className='w-28' src="exhibitor/leaf1.png" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="imagebackground">
                <img src="exhibitor/th (2).jpg" alt="" />
              </a>
        </div>
        <div>
        <a href="#" className="imagebackground">
                <img src="exhibitor/th (3).jpg" alt="" />
              </a>
        </div>

        <div>
        <a href="#" className="imagebackground">
                <img src="exhibitor/th (4).jpg" alt="" />
              </a>
        </div>

        <div>
        <a href="#" className="imagebackground">
                <img src="exhibitor/th (5).jpg" alt="" />
              </a>
        </div>
      </Slider>


      {/* slider */}




    </div>
  )
}

export default Exhibitor