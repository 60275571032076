import * as Yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const ExhibitorRegistrationvalid=Yup.object({
    Name:Yup.string().min(3).required("Please  Enter Name"),
    Company:Yup.string().min(5).required("Please Enter Company Name"),
    GST:Yup.string().min(5).required("Please Enter GSTNO"),
    EmailId:Yup.string().email("Please Enter Valid E-mail").required("Please Enter E-mail"),
    City:Yup.string().min(3).required("Please  Enter City"),
    PinCode:Yup.string().min(3).required("Please  Enter PinCode"),
    Selectanoption: Yup.string().required("Please select a Category"),
    MobileNo:Yup.string().max(10).required("Please  Enter Number").matches(phoneRegExp, 'Phone number is not valid') 
})